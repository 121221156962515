<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Tool</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Tool</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />


      <ToolTable v-for="tool in tools" :key="tool.id" :title="tool.name" :tools="tool.prices" @updateTool="updateTool"
        @showModalUpdateTool="showModalUpdateTool" />

      <div class="modal fade" id="updateToolModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Sửa Tool
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="text-start row" @submit.prevent="updateTool(updateToolItem)">
                <div class="mb-3 col-12 col-md-12">
                  <label for="" class="form-label">Name</label>
                  <input type="text" v-model="updateToolItem.name" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Status</label>
                  <input type="text" v-model="updateToolItem.status" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Package Name</label>
                  <input type="text" v-model="updateToolItem.package_name" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Tool</label>
                  <input type="text" v-model="updateToolItem.tool" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Giá bán</label>
                  <input type="text" v-model="updateToolItem.prices" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">% Chiết khấu</label>
                  <input type="text" v-model="updateToolItem.percent" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Giá cho mem</label>
                  <input type="text" v-model="updateToolItem.fix_coin_job" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Min</label>
                  <input type="text" v-model="updateToolItem.min" class="form-control" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="form-label">Max</label>
                  <input type="text" v-model="updateToolItem.max" class="form-control" />
                </div>
                <div class="col-12 mt-2">
                  <button class="btn btn-success py-1 w-25">Xác nhận</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toolApi from "@/api/tool";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";
import ToolTable from "./ToolTable.vue";

export default {
  name: "Tool",
  components: {
    LoadingVue,
    ToolTable,
  },
  data() {
    return {
      limit: 50,
      tools: [],
      loading: false,
      updateToolItem: {},
    };
  },
  created() {
    this.getTools();
  },
  methods: {
    async getTools() {
      const params = {
        limit: this.limit,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await toolApi.list(queries);
      this.loading = false;
      if (response.success) {
        this.tools = response.data;
      } else {
        this.$toastr.error(response.message);
      }
    },
    showModalUpdateTool(tool) {
      this.updateToolItem = tool;
      console.log(this.updateToolItem);
    },
    async updateTool(tool) {
      const response = await toolApi.update(tool);
      if (response?.success) {
        await this.getTools();
        this.$swal("Hoàn thành", response?.message || 'Thành công', "success");
      } else {
        this.$swal("Lỗi", response?.message || 'thất bại', "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
