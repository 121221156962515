<template>
  <div class="row">
    <div class="col-md-12">
      <datatable :stt="true" :title="title" :columns="columns" :rows="toolsWithStatus" :defaultPerPage="10">
        <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
        <td slot="tbody-tr-o" class="text-center" slot-scope="props">
          <button class="btn font-10 py-1 btn-xs" :class="props.row.status ? 'btn-danger' : 'btn-success'"
            @click="updateStatus(props.row)">
            {{ props.row.status ? "Đóng" : "Mở" }}
          </button>
          <button @click="updateTool(props.row)" class="btn btn-info font-10 py-1 btn-xs mx-2" data-bs-toggle="modal"
            data-bs-target="#updateToolModal">
            Sửa
          </button>
        </td>
      </datatable>
    </div>


  </div>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";

export default {
  name: "ToolTable",
  props: {
    title: String,
    tools: Array,
  },
  components: {
    Datatable,
  },
  computed: {
    toolsWithStatus() {
      return this.tools.map((item) => {
        item.status_name = !item.status ? "<span class='text-danger'>Đang Đóng</span>" : "<span class='text-success'>Đang mở</span>";
        return item;
      }).sort((a, b) => b.status - a.status);
    },
  },
  data() {
    return {
      columns: [
        { label: "Id", field: "id" },
        { label: "Name", field: "name" },
        { label: "Status", field: "status_name", html: true },
        { label: "Package name", field: "package_name" },
        { label: "Tool", field: "tool" },
        { label: "Giá", field: "prices", numeric: true },
        { label: "Giá Mem", field: "fix_coin_job", numeric: true, class: "bold text-danger" },
        { label: "% CK", field: "percent", numeric: true },
        { label: "Min", field: "min", numeric: true },
        { label: "Max", field: "max", numeric: true },
      ],
    };
  },
  methods: {
    async updateStatus(tool) {
      const params = {
        id: tool.id,
        status: !tool.status,
      };
      this.$emit("updateTool", params);
    },
    async updateTool(tool) {
      this.$emit("showModalUpdateTool", tool);
    },
  },
};
</script>
